import axios from "axios";

const httpClient = axios.create({
  //  baseURL: 'http://ec2-3-145-36-242.us-east-2.compute.amazonaws.com/api/mega-city',
  // baseURL: "https://megaa-city.herokuapp.com/api/mega-city",
  baseURL: 'https://nammuruapp-ea9e6a63d15c.herokuapp.com/api/mega-city',
  // baseURL: "http://localhost:8082/api/mega-city",
  // headers: [""],
});

httpClient.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const GET = ({url, params = {}, headers = {}}) => {
  return httpClient.get(url, {
    params,
    headers,
  });
};

export const POST = ({url, requestBody, headers = {}}) => {
  return httpClient.post(url, requestBody, {
    headers,
  });
};

export const DELET = (url, params = {}, headers = {}) => {
  return httpClient.delete(url, {
    headers,
    params,
  });
};

export const PUT = ({url, requestBody, headers = {}}) => {
  return httpClient.put(url, requestBody, {
    headers,
  });
};


export const setDefaultHeaders = (headerName, headerValue) => {
  httpClient.defaults.headers.common[headerName] = headerValue;
};

export const removeDefaultHeaders = (headerName) => {
  httpClient.defaults.headers.common[headerName] = "";
};