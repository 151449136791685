import React, { Suspense } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import Login from './components/Login'
import Dashboard from './components/Dashboard'

const RestaurantList = React.lazy(() => import('./components/Restaurants/List'));
const RestaurantDetails = React.lazy(() => import('./components/Restaurants/Details'));
const MyRestaurant = React.lazy(() => import("components/MyRestaurant"));
const MyRestaurantPage = React.lazy(() => import("components/Restaurants/myRestaurant"));
const FirmDetails = React.lazy(() => import("components/common/firmDetails"));
const UserOrders = React.lazy(() => import("components/Users/userOrders"));
const Cart = React.lazy(() => import("components/Cart"));
const Address = React.lazy(() => import("components/common/address"));
const ThanksNote = React.lazy(() => import("components/common/thanks-note"));
const RestaurantOrders = React.lazy(() => import("components/Restaurants/restaurantOrders"));
const RestaurantPayments = React.lazy(() => import("components/Restaurants/restaurantPayments"));
const RestaurantReviews = React.lazy(() => import("components/Restaurants/restaurantReviews"));
const RestaurantDishes = React.lazy(() => import("components/Restaurants/restaurantDishes"));
const RestaurantImages = React.lazy(() => import("components/Restaurants/restaurantImages"));
const RestaurantCoupons = React.lazy(() => import("components/Restaurants/restaurantCoupons"));
const CategoryPriority = React.lazy(() => import("components/Restaurants/priority"));
const Settings = React.lazy(() => import("components/Settings/settings"));
const Groceries = React.lazy(() => import("components/Groceries/groceries"));
const AddFirmForm = React.lazy(() => import("components/common/addFirmForm"));
const AddItemForm = React.lazy(() => import("components/common/addItemForm"));
const PrivacyPolicy = React.lazy(() => import('components/common/pdfReader'));
const Coupons = React.lazy(() => import("components/Cart/couponsList"));
const AddAddress = React.lazy(() => import("components/common/addAddress"));
const ScrollToTop = React.lazy(() => import("components/common/scrollToTop"));
import SkeletonComp from 'components/Dashboard/skeleton';
import DeleteAccount from 'components/delete-account';

const AppRoutes = ({ isLoggedIn }) => {
    return (
        <Router>
            <ScrollToTop>
                <Suspense fallback={<SkeletonComp />}>
                <Routes>
                    <Route path="/" element={isLoggedIn ? <Dashboard /> : <Login />} />
                    <Route path="/restaurants" element={<RestaurantList />} />
                    <Route path="/restaurants/:id" element={<RestaurantDetails />} />
                    <Route path="/myrestaurant" element={< MyRestaurant />} />
                    <Route path="/firmDetails" element={< FirmDetails />} />
                    <Route path="/userOrders" element={< UserOrders />} />
                    <Route path="/restaurantOrders" element={< RestaurantOrders />} />
                    <Route path="/cart" element={< Cart />} />
                    <Route path="/address" element={< Address />} />
                    <Route path="/myrestaurantpage" element={< MyRestaurantPage />} />
                    <Route path="/restaurantPayments" element={< RestaurantPayments />} />
                    <Route path="/restaurantDishes" element={< RestaurantDishes />} />
                    <Route path="/restaurantReviews" element={< RestaurantReviews />} />
                    <Route path="/restaurantimages" element={< RestaurantImages />} />
                    <Route path="/restaurantcoupons" element={< RestaurantCoupons />} />
                    <Route path="/priority" element={< CategoryPriority />} />
                    <Route path="/groceriesdashboard" element={< Groceries />} />
                    <Route path="/settings" element={< Settings />} />
                    <Route path="/thanks" element={< ThanksNote />} />
                    <Route path="/couponsList" element={< Coupons />} />
                    <Route path="/addfirmform" element={< AddFirmForm />} />
                    <Route path="/additemform" element={< AddItemForm />} />
                    <Route path="/addaddressform" element={< AddAddress />} />
                    <Route path="/privacypolicy" element={< PrivacyPolicy />} />
                    <Route path="/deleteaccount" element={< DeleteAccount />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
                </Suspense>
            </ScrollToTop>
        </Router >
    );
}
export default AppRoutes