import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Paper, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { POST } from 'restClient';
import Header from '../common/Header';

const AccountDeletionRequest = () => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');
    setResponseMessage('');

    // Validate email format (simple validation here)
    if (email.length < 10 || email.length > 10) {
      setErrorMessage('Please enter a valid Phone Number.');
      setIsSubmitting(false);
      return;
    }

    try {
      // Call API or backend function for account deletion request
      // Example: await deleteAccount(email);
      await POST({url: '/delete-account', requestBody: {
        phone: email
      }})
      // Placeholder success message
      setResponseMessage('Your account deletion request has been submitted. Please allow up to 7 days for your data to be completely removed from our systems.');
    } catch (error) {
      setErrorMessage('There was an issue processing your request. Please try again later.');
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Header title="Account Removal - Nammuru" />
    <Box component="main" sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>

      <Paper sx={{ width: '100%', maxWidth: 600, padding: 4, borderRadius: 2 }}>
        <Typography variant="h5" gutterBottom>Request Account Deletion</Typography>
        <Typography variant="body1" paragraph>
          If you wish to permanently delete your account and all associated data, please fill in the form below.
          This action is irreversible.
        </Typography>

        <form onSubmit={handleFormSubmit}>
          <TextField
            label="Your Phone Number"
            type="number"
            variant="outlined"
            fullWidth
            required
            value={email}
            onChange={handleEmailChange}
            sx={{ marginBottom: 2 }}
          />

          <Button
            type="submit"
            variant="contained"
            color="error"
            fullWidth
            disabled={isSubmitting}
            sx={{ marginBottom: 2 }}
          >
            {isSubmitting ? <CircularProgress size={24} /> : 'Submit Request'}
          </Button>
        </form>

        {responseMessage && (
          <Typography variant="body1" color="success.main" sx={{ marginBottom: 2 }}>
            {responseMessage}
          </Typography>
        )}

        {errorMessage && (
          <Typography variant="body1" color="error.main" sx={{ marginBottom: 2 }}>
            {errorMessage}
          </Typography>
        )}

        <Typography variant="h6" sx={{ marginTop: 4 }} gutterBottom>
          Terms & Conditions:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Once your account is deleted, all your personal data, order history, and account details will be permanently removed."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="It may take up to 7 business days for the deletion process to be fully completed."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="After your account is deleted, you will no longer be able to access any previous orders or account information."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="If you change your mind, please contact us within the first 48 hours to cancel the deletion request."
            />
          </ListItem>
        </List>
      </Paper>
    </Box>
    </>

  );
};

export default AccountDeletionRequest;
