import * as React from "react";
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Snackbar, Alert } from '@mui/material';
import { Provider as ReduxProvider, useSelector } from "react-redux";
import { getCurrentTheme } from './global/ThemeReducer'
import rootSaga from "./rootSaga";
import configureStore from "./rootStore";
import Routes from "./routes";
import AppWrapper from './AppStyles'
import { getNotification } from 'containers/DashBoard/reducer'
import Skeleton from 'components/Dashboard/skeleton';
import { GET, setDefaultHeaders } from 'restClient'
import dispatchHook from 'components/common/dispatchHook'
import { LOGIN_USER_SUCCESS } from 'containers/LoginPage/constants'
import { getLoggedInUser } from 'containers/LoginPage/reducer';

import { SAVE_CURRENT_RESTAURANT } from 'containers/Restaurants/RestaurantsList/constants'
import { handleNativeBackPress } from "containers/LoginPage/actions";
import ConfirmModal from "components/common/confirm-modal";

const { store, runSaga } = configureStore({});

runSaga(rootSaga);
const Redux = React.memo(() => {
  return (
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  );
})

const App = React.memo(() => {
  const theme = useSelector(getCurrentTheme)
  const [loggedIn, setLoggedIn] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const notification = useSelector(getNotification)
  const loggedInUser = useSelector(getLoggedInUser);
  const [dispatch] = dispatchHook();
  const [confirmModal, setConfirmModal] = React.useState(false)

  React.useEffect(() => {
    if (notification) {
      setOpen(true)
      setTimeout(() => setOpen(false), 3000)
    }
  }, [notification]);

  React.useEffect(() => {
    if (loggedInUser.id && localStorage.getItem('x-access-token'))
      setLoggedIn(true)
    else setLoggedIn(false)
  }, [loggedInUser]);

  async function init() {
    try {
      if(window?.location?.pathname === "/privacypolicy" ||
      window?.location?.pathname === "/settings" ||
      window?.location?.pathname === "/deleteaccount") {
        setLoggedIn(true)
        setLoading(false)
        return  
      }
      setLoggedIn(false)
      setLoading(true)
      const token = localStorage.getItem('x-access-token')
      setDefaultHeaders('x-access-token', token);
      const response = await GET({
        url: `/auth/init?token=${undefined}&os=WEB`,
      });
      const data = response.data.message;
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: data,
      });
      if (data?.user?.role === 'OWNER' && data?.user?.hotels?.length > 0) {
        dispatch({
          type: SAVE_CURRENT_RESTAURANT,
          payload: response?.data?.message?.user?.hotels[0],
        });
      }
      setLoggedIn(true);
    } catch (error) {
      console.log(error)
      localStorage.removeItem('x-access-token')
      if (window.location.pathname !== '/') window.location = '/'
      setLoggedIn(false);
    } finally {
      setLoading(false)
    }
  }

  function handleBackButtonPress() {
    let path = window.location.pathname;
    if (path === '/dashboard' || path === '/') {
      setConfirmModal(true);
    }
    else
      dispatch(handleNativeBackPress(true));

  }

  React.useEffect(() => {
    init()
    if (window.innerWidth < 600) {
      document.getElementById("root").style.width = "100%"
      document.getElementById("root").style.left = "0%"
    } else if (window.innerWidth < 800) {
      document.getElementById("root").style.width = "80%"
      document.getElementById("root").style.left = "10%"
    } else if (window.innerWidth < 1000) {
      document.getElementById("root").style.width = "70%"
      document.getElementById("root").style.left = "15%"
    } else {
      document.getElementById("root").style.width = "40%"
      document.getElementById("root").style.left = "30%"
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        style={{ position: 'absolute', zIndex: '999999' }}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} variant="filled" severity={notification?.type || "info"} sx={{ width: '100%' }}>
          {notification?.message}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <AppWrapper style={{
        minHeight: '100vh',
        overflow: 'auto'
      }} >
        {loading ?
          <Skeleton />
          :
          <Routes isLoggedIn={loggedIn} />
        }
        <ConfirmModal
          message=""
          title="Are you sure you want to leave us??"
          okText="Exit App"
          showConfirm={confirmModal}
          // setShowConfirm={() => setConfirmModal(false)}
          handleCancel={() => {
            setConfirmModal(false);
            dispatch(handleNativeBackPress(false));
            // setPressCount(0);
          }}
          handleOk={() => {
            setConfirmModal(false);
            // setPressCount(0);
            window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'backBtn', close: true }))
          }}
        />
      </AppWrapper>
    </ThemeProvider>
  )
})
export default Redux